import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})

export class FullLayoutComponent implements OnInit {
    options = {
        direction: 'ltr'
    };
    constructor(    
        private _route: ActivatedRoute,
        private _router: Router) {}
        ngOnInit() {
        }
}
