<!-- Sidebar Header starts -->
<div class="sidebar-header">
  <div class="logo clearfix">
    <a [routerLink]="['/']" class="logo-text float-left">
      <div class="logo-img">
        <img class="logo-main" src="assets/img/logos/vuelandia-logo-grises.png" />
      </div>
      <span class="text align-middle">VUELANDIA</span>
    </a>
    <a class="nav-toggle d-none d-sm-none d-md-none d-lg-block my-toggle-side" id="sidebarToggle" href="javascript:;">
      <i class="ft-toggle-right toggle-icon" data-toggle="expanded"></i>
    </a>
    <a class="nav-close d-block d-md-block d-lg-none d-xl-none my-toggle-side" id="sidebarClose" href="javascript:;">
      <i class="ft-x"></i>
    </a>
  </div>
</div>
<!-- Sidebar Header Ends -->

<!-- Sidebar Content starts -->
<div *ngFor="let menuItem of menuItems">
  <div class="sidebar-content">
    <div class="nav-container">
      <ul class="navigation">
        <!-- First level menu -->
        <li *ngFor="let menuItem of menuItems" [ngClass]="[menuItem.class]"
          [routerLinkActive]="menuItem.submenu.length != 0 ? '' : 'active'" [routerLinkActiveOptions]="{ exact: true }">
          <a [routerLink]="menuItem.class === '' ? [menuItem.path] : null"
            *ngIf="!menuItem.isExternalLink; else externalLinkBlock">
            <i [ngClass]="[menuItem.icon]"></i>
            <span class="menu-title">{{ menuItem.title }}</span>
            <span *ngIf="menuItem.badge != ''" [ngClass]="[menuItem.badgeClass]">{{ menuItem.badge }}</span>
          </a>
          <ng-template #externalLinkBlock>
            <a [href]="[menuItem.path]" target="_blank">
              <i [ngClass]="[menuItem.icon]"></i>
              <span class="menu-title">{{ menuItem.title }}</span>
              <span *ngIf="menuItem.badge != ''" [ngClass]="[menuItem.badgeClass]">{{ menuItem.badge }}</span>
            </a>
          </ng-template>
          <!-- Second level menu -->
          <ul class="menu-content" *ngIf="menuItem.submenu.length > 0">
            <li *ngFor="let menuSubItem of menuItem.submenu" [routerLinkActive]="
                menuSubItem.submenu.length > 0 ? '' : 'active'
              " [ngClass]="[menuSubItem.class]">
              <a [routerLink]="
                  menuSubItem.submenu.length > 0 ? null : [menuSubItem.path]
                " *ngIf="!menuSubItem.isExternalLink; else externalSubLinkBlock">
                <i [ngClass]="[menuSubItem.icon]"></i>
                <span class="menu-title">{{ menuSubItem.title }}</span>
                <span *ngIf="menuSubItem.badge != ''" [ngClass]="[menuSubItem.badgeClass]">{{ menuSubItem.badge
                  }}</span>
              </a>
              <ng-template #externalSubLinkBlock>
                <a [href]="[menuSubItem.path]">
                  <i [ngClass]="[menuSubItem.icon]"></i>
                  <span class="menu-title">{{ menuSubItem.title }}</span>
                  <span *ngIf="menuSubItem.badge != ''" [ngClass]="[menuSubItem.badgeClass]">{{ menuSubItem.badge
                    }}</span>
                </a>
              </ng-template>
              <!-- Third level menu -->
              <ul class="menu-content" *ngIf="menuSubItem.submenu.length > 0">
                <li *ngFor="let menuSubsubItem of menuSubItem.submenu" routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }" [ngClass]="[menuSubsubItem.class]">
                  <a [routerLink]="[menuSubsubItem.path]" *ngIf="
                      !menuSubsubItem.isExternalLink;
                      else externalSubSubLinkBlock
                    ">
                    <i [ngClass]="[menuSubsubItem.icon]"></i>
                    <span class="menu-title">{{ menuSubsubItem.title }}</span>
                    <span *ngIf="menuSubsubItem.badge != ''" [ngClass]="[menuSubsubItem.badgeClass]">{{
                      menuSubsubItem.badge }}</span>
                  </a>
                  <ng-template #externalSubSubLinkBlock>
                    <a [href]="[menuSubsubItem.path]">
                      <i [ngClass]="[menuSubsubItem.icon]"></i>
                      <span class="menu-title">{{ menuSubsubItem.title }}</span>
                      <span *ngIf="menuSubsubItem.badge != ''" [ngClass]="[menuSubsubItem.badgeClass]">{{
                        menuSubsubItem.badge }}</span>
                    </a>
                  </ng-template>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</div>