import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { FullLayoutComponent } from './layouts/home/home.component';

import { Full_ROUTES } from './shared/routes/home.routes';

import { SigninOidcComponent } from './signin-oidc/signin-oidc.component';

import {RequiredAuthenticatedUserRouteGuardService} from './shared/required-authenticated-user-route-guard.service';
import { RedirectSilentRenewComponent } from './redirect-silent-renew/redirect-silent-renew.component';


const appRoutes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full', canActivate: [RequiredAuthenticatedUserRouteGuardService] },
  { path: '', component: FullLayoutComponent, data: { title: 'Home Page' },
    children: Full_ROUTES, canActivate: [RequiredAuthenticatedUserRouteGuardService] },
  { path: 'signin-oidc', component: SigninOidcComponent},
  { path: 'redirect-silentrenew', component: RedirectSilentRenewComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})

export class AppRoutingModule {

}
