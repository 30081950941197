import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
    // {
    //     path: '/home', title: 'Home', icon: 'fas fa-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    // },
    {
      path: '', title: 'Reservations', icon: 'far fa-calendar-alt', class: 'has-sub', badge: '',
      badgeClass: '', isExternalLink: false, submenu: [
        {
          path: '/hotel-reservations', title: 'Hotels', icon: 'fas fa-hotel',
          class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
        },
        {
          path: '/flight-reservations', title: 'Flights', icon: 'fas fa-plane',
          class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
        },
        {
          path: '/cars-reservations', title: 'Cars', icon: 'fas fa-car-side',
          class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
        },
        {
          path: '/transfer-reservations', title: 'Transfer', icon: 'fas fa-exchange-alt',
          class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
        },
        {
          path: '/general-reservations', title: 'General', icon: 'far fa-calendar',
          class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
        }
      ]
    },
    {
      path: '/users', title: 'Users', icon: 'fas fa-user-tie',
      class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
      path: '', title: 'Clients', icon: 'fas fa-building', class: 'has-sub', badge: '',
      badgeClass: '', isExternalLink: false, submenu: [
        {
          path: '/agencies', title: 'Agency', icon: 'far fa-building',
          class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
        },
        {
          path: '/leads', title: 'Agents', icon: 'fas fa-angle-right',
          class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
        },
        {
          path: '/fees', title: 'Fees', icon: 'fas fa-angle-right',
          class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
        },
        {
          path: '/fees-group', title: 'Fees Group', icon: 'fas fa-angle-right',
          class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
        },
        {
          path: '/import-leads', title: 'Import', icon: 'fas fa-file-import',
          class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
        },
        {
          path: '/import-documents-leads', title: 'Import Docs', icon: 'fas fa-file-import',
          class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
        },
        {
          path: '/groups', title: 'Groups', icon: 'fas fa-angle-right',
          class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
        },
        // {
        //   path: '/merlinx-agents', title: 'Merlinx Agents', icon: 'fab fa-avianex',
        //   class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
        // },
      ]
    },
    {
      path: '', title: 'Invoices', icon: 'fas fa-file-invoice', class: 'has-sub', badge: '',
      badgeClass: '', isExternalLink: false, submenu: [
        {
          path: '/invoicing-company', title: 'Company', icon: 'far fa-building',
          class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
        },
        {
          path: '/invoices', title: 'Invoices', icon: 'fas fa-file-invoice-dollar',
          class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
        },
        {
          path: '/counter', title: 'Counter', icon: 'fas fa-receipt',
          class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
        },
      ]
    },
    {
      path: '', title: 'Flights Database', icon: 'fas fa-plane', class: 'has-sub', badge: '',
      badgeClass: '', isExternalLink: false, submenu: [
        {
          path: '/code-finder', title: 'Code Finder', icon: 'fas fa-plane',
          class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
        },
        {
          path: '/office', title: 'Office ID', icon: 'far fa-building',
          class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
        }
      ]
    },
];
