export const environment = {
  production: true,
  //apiUrlImage: 'https://api.vuelandia.com',
  apiUrlImage: 'https://apicrm.vuelandia.com',
  //apiUrl: 'https://api.vuelandia.com/api',
  apiUrl: 'https://apicrm.vuelandia.com/api',
  apiInvoicingUrl: 'https://msinvoicing.vuelandia.com/api',
  apiZOHOUrl: 'https://msZOHO.vuelandia.com/api',
  resetUrl: 'https://idp.vuelandia.com/account',
  openIdConnectSettings: {
    authority: 'https://idp.vuelandia.com/',
    client_id: 'VuelandiaBackOfficeClient',
    //redirect_uri: 'https://intranet.vuelandia.com/signin-oidc',
    redirect_uri: 'https://crm.vuelandia.com/signin-oidc',
    scope: 'openid profile roles',
    response_type: 'id_token token',
    //post_logout_redirect_uri: 'https://intranet.vuelandia.com/',
    post_logout_redirect_uri: 'https://crm.vuelandia.com/',
    automaticSilentRenew: true,
    //silent_redirect_uri: 'https://intranet.vuelandia.com/redirect-silentrenew'
    silent_redirect_uri: 'https://crm.vuelandia.com/redirect-silentrenew'
  }
};
