import { Component, OnInit } from '@angular/core';
import { ROUTES } from './sidebar-routes.config';
import { RouteInfo } from './sidebar.metadata';
import { Router, ActivatedRoute } from '@angular/router';

declare var $: any;
@Component({
    // moduleId: module.id,
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];

    title: string = "";
    constructor(private router: Router,
        private route: ActivatedRoute) {
    }

    ngOnInit() {
        $.getScript('./assets/js/app-sidebar.js');
   /*     ROUTES.forEach(x=> x.submenu.forEach(x=> console.log(x.title))  ) */
        this.menuItems = ROUTES.filter(menuItem => menuItem);
    }

    recogerSubmenu(nombre){
        this.title = nombre
        console.log(nombre)
        console.log(this.title)

    }

}
