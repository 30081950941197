import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  lastLocation: string;
  currentLocation: string;
  lastApiUrl: string;
  searchValue: string;
  objectId: number;
  componentName: string;
  goBack: boolean;
  currentLeadPage: string;
  constructor() { }
}
