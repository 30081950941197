
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { AppComponent } from './app.component';

import { AuthService } from './shared/auth/auth.service';
import { AuthGuard } from './shared/auth/auth-guard.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import * as $ from 'jquery';

import { FullLayoutComponent } from './layouts/home/home.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { OpenIdConnectService } from './shared/open-id-connect.service';
import { SigninOidcComponent } from './signin-oidc/signin-oidc.component';
import { RequiredAuthenticatedUserRouteGuardService } from './shared/required-authenticated-user-route-guard.service';
import 'automapper-ts/dist/automapper';
import { RedirectSilentRenewComponent } from './redirect-silent-renew/redirect-silent-renew.component';
import { AddAuthorizationHeaderInterceptor } from './shared/add-authorization-header-interceptor';
import { DragulaModule } from 'ng2-dragula';
import { LocationService } from './shared/services/location.service';

@NgModule({
    declarations: [
        AppComponent,
        FullLayoutComponent,
        SigninOidcComponent,
        RedirectSilentRenewComponent,
    ],
    imports: [
        BrowserAnimationsModule,
        AppRoutingModule,
        SharedModule,
        HttpClientModule,
        ReactiveFormsModule,
        FormsModule,
        NgbModule.forRoot(),
        DragulaModule.forRoot()
    ],
    providers: [
      {
        provide: HTTP_INTERCEPTORS,
        useClass: AddAuthorizationHeaderInterceptor,
        multi: true
      },
        AuthService,
        AuthGuard,
        NgbActiveModal,
        OpenIdConnectService,
        RequiredAuthenticatedUserRouteGuardService,
        LocationService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

}
