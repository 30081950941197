import { Routes, RouterModule } from '@angular/router';

// Route for content layout with sidebar, navbar and footer
export const Full_ROUTES: Routes = [
  {
    path: 'home',
    loadChildren: './pages/home-page/home-pages.module#HomePagesModule'
  },
  {
    path: 'users',
    loadChildren: './users/users.module#UsersModule'
  },
  {
    path: 'companies',
    loadChildren: './companies/companies.module#CompaniesModule'
  },
  {
    path: 'agencies',
    loadChildren: './agencies/agencies.module#AgenciesModule'
  },
  {
    path: 'bas-users',
    loadChildren: './bas-users/bas-users.module#BasUsersModule'
  },
  {
    path: 'merlinx-agents',
    loadChildren: './merlinx-agents/merlinx-agents.module#MerlinxAgentsModule'
  },
  {
    path: 'hotel-reservations',
    loadChildren: './hotel-reservations/hotel-reservations.module#HotelReservationsModule'
  },
  {
    path: 'flight-reservations',
    loadChildren: './flight-reservations/flight-reservations.module#FlightReservationsModule'
  },
  {
    path: 'cars-reservations',
    loadChildren: './cars-reservations/cars-reservations.module#CarsReservationsModule'
  },
  {
    path: 'transfer-reservations',
    loadChildren: './transfer-reservations/transfer-reservations.module#TransferReservationsModule'
  },
  {
    path: 'invoicing-company',
    loadChildren: './invoicing-company/invoicing-company.module#InvoicingCompanyModule'
  },
  {
    path: 'invoices',
    loadChildren: './invoices/invoices.module#InvoicesModule'
  },
  {
    path: 'leads',
    loadChildren: './leads/leads.module#LeadsModule'
  },
  {
    path: 'fees',
    loadChildren: './fees/fees.module#FeesModule'
  },
  {
    path: 'fees-group',
    loadChildren: './fees-group/fees-group.module#FeesGroupModule'
  },
  {
    path: 'import-leads',
    loadChildren: './import-leads/import-leads.module#ImportLeadsModule'
  },
  {
    path: 'import-documents-leads',
    loadChildren: './import-documents-leads/import-documents-leads.module#ImportDocumentsLeadsModule'
  },
  {
    path: 'groups',
    loadChildren: './groups/groups.module#GroupsModule'
  },
  {
    path: 'general-reservations',
    loadChildren: './general-reservations/general-reservations.module#GeneralReservationsModule'
  },
  {
    path: 'export-leads',
    loadChildren: './export-leads/export-leads.module#ExportLeadsModule'
  },
  {
    path: 'code-finder',
    loadChildren: './code-finder/code-finder.module#CodeFinderModule'
  },
  {
    path: 'office',
    loadChildren: './office/office.module#OfficeModule'
  }
];
