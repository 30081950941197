import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { OpenIdConnectService } from './open-id-connect.service';

@Injectable({
  providedIn: 'root'
})
export class RequiredAuthenticatedUserRouteGuardService implements CanActivate {

  constructor(private opneIdConnectService: OpenIdConnectService,
    private router: Router) { }

    canActivate() {
      if (this.opneIdConnectService.userAvialable) {
        return true;
      } else {
        this.opneIdConnectService.triggerSigmIn();
        return false;
      }
    }
}
